<template>
  <div>
    <park-info-header></park-info-header>
    <div class="top-bg">
      <div class="Settlein-box">
        <div class="box-item">
          <img src="../../assets/img/park/process.png" />
          <div class="settlein-text">入驻流程</div>
        </div>
        <div class="box-item">
          <img src="../../assets/img/park/enterprise.png" />
          <div class="settlein-text">入驻企业</div>
        </div>
        <div class="box-item">
          <img src="../../assets/img/park/cooperation.png" />
          <div class="settlein-text">合作企业</div>
        </div>
      </div>
    </div>
    <div class="process">
      <img class="title" src="../../assets/img/park/title4.png" />
      <!-- <div class="text">
        网上产业园，是指依托中国成都人力资源服务产业园网络平台吸引机构线上进驻，
        为入驻机构提供品牌传播平台，积极推动企业的发展，构建良好的企业生态环境，鼓励和扶持企业成长，
        利用信息化手段最大化实现产业集聚及资源整合，以“集聚产业、培育市场、孵化企业、聚焦人才”为核心功能定位，
        着力打造多元化、多层次、专业化的人力资源服务产业链。
      </div> -->
      <div class="arrow-box">
        <div class="blue-item">一、洽谈入园意向</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">二、提交入园申请材料</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">三、评估入园条件、审批</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">四、签订入园协议</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">五、缴纳保证金、租金</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">六、装修方案审批</div>
        <img class="arrow" src="../../assets/img/park/arrow.png" />
        <div class="blue-item">七、企业（项目）进驻园区</div>
      </div>
    </div>
    <div class="tabbox" v-if="tabindex">
      <div class="tab-top">
        <div :class="tabindex == true ? 'tab-index1' : 'tab-index2'" @click="changetabindex()">招租空间</div>
        <div :class="tabindex == false ? 'tab-index1' : 'tab-index2'" @click="changetabindex()">入驻企业</div>
      </div>
      <!-- <img class="tab1" src="../../assets/img/park/tab1.png" /> -->
      <div class="showImg">
        <!-- 轮播图片 -->
        <img class="tab1" v-for="(item,index) in stepItem.split(',',10)" :key="index" :src="item" v-show="index === currentIndex">
        <!-- //左侧按钮 -->
        <div @click="clickIconup" class="iconDiv icon-left">
          <i class="el-icon-arrow-left" style="color:#fff;font-size:12px;"></i>
        </div>
        <!-- //右侧按钮 -->
        <div @click="clickIcondown" class="iconDiv icon-right">
          <i class="el-icon-arrow-right" style="color:#fff;font-size:12px;"></i>
        </div>
        <div class="banner-circle">
          <ul>
            <li @click="changeImg(index)" v-for="(item,index) in stepItem.split(',',10)" :key="index" :class="index === currentIndex ? 'active' : ''">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tabbox" v-if="!tabindex">
      <div class="tab-top">
        <div :class="tabindex == true ? 'tab-index1' : 'tab-index2'" @click="changetabindex()">招租空间</div>
        <div :class="tabindex == false ? 'tab-index1' : 'tab-index2'" @click="changetabindex()">入驻企业</div>
      </div>
      <div class="showImg">
        <!-- 轮播图片 -->
        <img class="tab1" v-for="(item,index) in stepItem.split(',',10)" :key="index" :src="item" v-show="index === currentIndex">
        <!-- //左侧按钮 -->
        <div @click="clickIconup" class="iconDiv icon-left">
         <i class="el-icon-arrow-left" style="color:#fff;font-size:12px;"></i>
        </div>
        <!-- //右侧按钮 -->
        <div @click="clickIcondown" class="iconDiv icon-right">
          <i class="el-icon-arrow-right" style="color:#fff;font-size:12px;"></i>
        </div>
        <div class="banner-circle">
          <ul>
            <li @click="changeImg(index)" v-for="(item,index) in stepItem.split(',',10)" :key="index" :class="index === currentIndex ? 'active' : ''">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <park-info-footer></park-info-footer>
  </div>
</template>

<script>
import { entryProcessDetal } from '@/api/park'
import ParkInfoHeader from '@/components/ParkInfoHeader/index'
import ParkInfoFooter from '@/components/ParkInfoFooter/index'

export default {
  components: {
    ParkInfoHeader,
    ParkInfoFooter
  },
  data() {
    return {
      tabindex: true,
      currentIndex: 0,
      stepItem: {}
    }
  },
  name: 'CheckinGuide',

  created() {
    this.init()
  },

  methods: {
    async init() {
      const that = this
      const params = {
        pageNo: 1,
        pageSize: 10
      }
      const stepItem = await entryProcessDetal(params)
        .then((res) => {
          return Array.isArray(res.result.records) && res.result.records.length > 0 ? res.result.records[0] : {}
        })
        .catch((err) => {
          console.error(err)
          return {}
        })
      if (!stepItem) {
        that.$message.info('获取入驻步骤信息失败!')
        return
      }
      if (this.tabindex) {
        that.stepItem = stepItem.forLease
      } else {
        that.stepItem = stepItem.enterOrg
      }
    },

    changetabindex () {
      this.stepItem = {}
      this.currentIndex = 0
      this.tabindex = !this.tabindex
      this.init()
    },

    clickIconup () {
      if (this.currentIndex <= 0) {
          this.currentIndex = this.stepItem.split(',').length
        }
        this.currentIndex--
    },

    clickIcondown () {
      this.currentIndex++
        if (this.currentIndex >= this.stepItem.split(',').length) {
          this.currentIndex = 0
        }
        console.log(this.currentIndex)
    },

    changeImg (index) {
      this.currentIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
// @font-face {
//   font-family: 'SourceHanSansCN';
//   font-style: normal;
//   src: url('~@/assets/font/SourceHanSansCN.otf');
// }

// @font-face {
//   font-family: 'DFPSongW9-GB';
//   font-style: normal;
//   src: url('~@/assets/font/DFPSongW9-GB.ttf');
// }

.top-bg {
  background: url('~@/assets/img/park/top_bg.png') no-repeat center center;
  background-size: 100% 100%;
  width: 375px;
  height: 240px;
  padding: 120.5px 30.5px 0 31px;

  .Settlein-box {
    width: 313.5px;
    height: 99.5px;
    display: flex;

    .box-item {
      width: 73px;
      height: 99.5px;
      margin-right: 49px;

      img {
        width: 73px;
        height: 73px;
      }

      .settlein-text {
        margin-top: 12.5px;
        height: 14px;
        font-size: 15px;
        text-align: center;
        color: #ffffff;
        font-family: 'DFPSongW9-GB';
      }
    }
  }
}

.process {
  width: 375px;
  height: 545.5px;
  margin-top: 40px;

  .title {
    width: 175.5px;
    height: 22px;
    margin: 0 100.5px 29px 99px;
  }

  .text {
    font-family: 'SourceHanSansCN';
    font-weight: 400;
    width: 328px;
    height: 174.5px;
    font-size: 13px;
    text-align: justifyLeft;
    margin: 0 24.5px 35.5px 22.5px;
    line-height: 27px;
  }

  .arrow-box {
    width: 277.5px;
    height: 488.5px;

    .blue-item {
      margin: 0 47.5px 0 50px;
      width: 277.5px;
      height: 43px;
      border-radius: 5px;
      background: rgba(16, 115, 227, 1);
      font-weight: 500;
      font-family: 'SourceHanSansCN';
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }

    .arrow {
      width: 22.5px;
      height: 17px;
      margin: 4px 175px 0px 177.5px;
    }
  }
}

.tabbox {
  margin: 31px 9.5px 0px 10.5px;
  width: 335px;
  height: 307.5px;

  .tab-top {
    display: flex;
    width: 375px;

    .tab-index1 {
      width: 177.5px;
      height: 40px;
      background: rgba(254, 155, 2, 1);
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 500;
      font-family: 'SourceHanSansCN';
    }

    .tab-index2 {
      width: 177.5px;
      height: 40px;
      background: rgba(235, 235, 235, 1);
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 500;
      font-family: 'SourceHanSansCN';
    }
  }
  .showImg{
    position: relative;
    width: 355px;
    height: 262.5px;
    .tab1 {
      margin-top: 5px;
      width: 355px;
      height: 262.5px;
      background-size: 100% 100%;
    }
    .iconDiv{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      // border: 1px solid #666;
      // border-radius: 15px;
      // background-color: rgba(125,125,125,.2);
      line-height: 30px;
      text-align: center;
      font-size: 25px;
      cursor: pointer;
      z-index: 1000;
    }
    .icon-left{
      left: 10px;
    }
    .icon-right{
      right: 10px;
    }
    .banner-circle{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20px;
      ul{
        margin: 0 50px;
        height: 100%;
        text-align: right;
        li{
          display: inline-block;
          width: 8px;
          height: 8px;
          margin: 0 5px;
          border-radius: 4px;
          background-color: rgba(125,125,125,0.2);
          cursor: pointer;
        }
        .active{
          background-color: #004e98 !important; 
        }
      }
    }
  }
  
}
</style>
