var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "focus_on_module" }, [
      _c("div", { staticClass: "f_o_m_code" }, [
        _c("img", {
          staticClass: "code",
          attrs: { src: require("../../assets/img/park/code.png") }
        }),
        _c("div", { staticClass: "introduce" }, [
          _c("p", [_vm._v("· 江苏南通人力资源服务产业园 ·")]),
          _c("p", [_vm._v("集聚产业 拓展业态")]),
          _c("p", [_vm._v("孵化企业 培育市场")]),
          _c("p", [_vm._v("←长按关注")])
        ])
      ]),
      _c("img", {
        staticClass: "line",
        attrs: { src: require("../../assets/img/park/line.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }