<template>
  <div class="focus_on_module">
    <div class="f_o_m_code">
      <img src="../../assets/img/park/code.png" class="code" />
      <div class="introduce">
        <p>· 江苏南通人力资源服务产业园 ·</p>
        <p>集聚产业 拓展业态</p>
        <p>孵化企业 培育市场</p>
        <p>←长按关注</p>
      </div>
    </div>
    <img src="../../assets/img/park/line.png" class="line" />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.focus_on_module {
  height: 186px;
  padding: 24px 16.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .f_o_m_code {
    display: flex;

    .code {
      width: 138px;
      height: 138px;
    }

    .introduce {
      width: 201px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #1073e3;
      font-weight: 500;
      font-family: 'Source Han Sans CN Regular';
      font-size: 13px;
      text-align: center;
      line-height: 26px;
    }
  }

  .line {
    height: 2px;
    width: 339px;
    position: relative;
    top: -3px;
  }
}
</style>
